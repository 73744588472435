import React, { useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTheme } from "@emotion/react";
import { gql, useMutation } from "@apollo/client";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import LanguageSwitcher from "../components/language-switcher";
import SEO from "../components/seo";
import { getAlternateLang } from "../utils/alternate-lang";
import { gtmPushUserInfo } from "../services/auth";

export const query = graphql`
  query NewPassword($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const NewPassword = (props) => {
  const theme = useTheme();
  const { t, language } = useI18next();
  const NEW_PASSWORD = gql`
    mutation (
      $password: String!
      $passwordConfirmation: String!
      $code: String!
    ) {
      resetPassword(
        password: $password
        passwordConfirmation: $passwordConfirmation
        code: $code
      ) {
        jwt
        user {
          id
          userLastName
          email
          userFirstName
          country
          areasOfExpertise
          profession
        }
      }
    }
  `;

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "new-password",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  const [resetPassword] = useMutation(NEW_PASSWORD);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleChangePassword = (prop) => (event) => {
    setPassword(event.target.value);
  };
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChangeConfirmPassword = (prop) => (event) => {
    setConfirmPassword(event.target.value);
  };
  const [showMessageDifferentPassword, setShowMessageDifferentPassword] =
    useState(false);
  const [showMessageLinkExpired, setShowMessageLinkExpired] = useState(false);
  const handleSubmit = (event) => {
    let searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.has("code") ? searchParams.get("code") : "";
    if (password !== confirmPassword) {
      setShowMessageDifferentPassword(true);
      return;
    }
    resetPassword({
      variables: {
        password: password,
        passwordConfirmation: confirmPassword,
        code: code,
      },
    })
      .then((data) => {
        // Handle success.
        console.log("reset password : success ", data);
        navigate(`/${language}/login/`, {
          state: { prevPath: "/" },
        });
        return;
      })
      .catch((error) => {
        // Handle error.
        console.log(
          "reset password : an error occurred:",
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message
        );
        if (
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message === "Incorrect code provided."
        )
          setShowMessageLinkExpired(true);
      });

    return false;
  };

  const seo = {
    metaTitle: t("nav.newPassword"),
    metaDescription: t("nav.newPassword").toLocaleLowerCase(),
    linkAlternateHref: "/" + getAlternateLang(language) + "/new-password",
    linkHrefLang: getAlternateLang(language),
    noindex: true,
  };

  return (
    <Box
      sx={{
        [theme.breakpoints.down("xl")]: {
          maxWidth: "100vw",
        },
      }}
    >
      <SEO seo={seo} />
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          py: 8,
          gap: 8,
        }}
      >
        {showMessageLinkExpired ? (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              gap: 8,
            }}
          >
            <div>{t("login.linkExpired")}</div>
          </Container>
        ) : (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              gap: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <LanguageSwitcher
                alternateHref={`${props.pageContext.i18n.originalPath}${
                  props.location.search || ""
                }`}
              />
            </div>
            <Typography variant="h1">{t("login.resetPassword")}</Typography>
            <Typography varaint="body1">
              {t("login.pleaseEnterYourNewPassword")}
            </Typography>
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <InputLabel htmlFor="password">{t("login.password")}</InputLabel>
              <Input
                id="password"
                value={password}
                type={showPassword ? "text" : "password"}
                onChange={handleChangePassword("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <InputLabel htmlFor="confirmPassword">
                {t("login.confirmPassword")}
              </InputLabel>
              <Input
                id="confirmPassword"
                value={confirmPassword}
                type={showConfirmPassword ? "text" : "password"}
                onChange={handleChangeConfirmPassword("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {showMessageDifferentPassword && (
              <div>{t("login.differentPasswords")}</div>
            )}
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <Button
                disabled={!password}
                variant="contained"
                style={{
                  letterSpacing: "0.1em",
                  marginBottom: "1em",
                }}
                onClick={handleSubmit}
              >
                {t("login.savePassword")}
              </Button>
            </FormControl>
          </Container>
        )}
      </Container>
    </Box>
  );
};

export default NewPassword;
